// --------------------------------------------------------------
// Created On: 2023-08-21
// Author: Zachary Thomas
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants that represent permissions.
export const SUPER_ADMIN_PERMISSION = "SUPER_ADMIN";
export const CREATE_ROLES_PERMISSION = "CREATE_ROLES";
export const UPDATE_ROLES_PERMISSION = "UPDATE_ROLES";
export const DELETE_ROLES_PERMISSION = "DELETE_ROLES";
export const INVITE_USERS_PERMISSION = "INVITE_USERS";
export const UPDATE_USERS_PERMISSION = "UPDATE_USERS";
export const DELETE_USERS_PERMISSION = "DELETE_USERS";
export const CREATE_ASSETS_PERMISSION = "CREATE_ASSETS";
export const UPDATE_ASSETS_PERMISSION = "UPDATE_ASSETS";
export const DELETE_ASSETS_PERMISSION = "DELETE_ASSETS";
export const CREATE_USER_GROUPS_PERMISSION = "CREATE_USER_GROUPS";
export const UPDATE_USER_GROUPS_PERMISSION = "UPDATE_USER_GROUPS";
export const DELETE_USER_GROUPS_PERMISSION = "DELETE_USER_GROUPS";
export const CREATE_ASSET_GROUPS_PERMISSION = "CREATE_ASSET_GROUPS";
export const UPDATE_ASSET_GROUPS_PERMISSION = "UPDATE_ASSET_GROUPS";
export const DELETE_ASSET_GROUPS_PERMISSION = "DELETE_ASSET_GROUPS";
export const CREATE_MAINTENANCE_LOGS_PERMISSION = "CREATE_MAINTENANCE_LOGS";
export const UPDATE_MAINTENANCE_LOGS_PERMISSION = "UPDATE_MAINTENANCE_LOGS";
export const DELETE_MAINTENANCE_LOGS_PERMISSION = "DELETE_MAINTENANCE_LOGS";
export const CREATE_ASSET_RENTALS_PERMISSION = "CREATE_ASSET_RENTALS";
export const UPDATE_ASSET_RENTALS_PERMISSION = "UPDATE_ASSET_RENTALS";
export const REVOKE_ASSET_RENTALS_PERMISSION = "REVOKE_ASSET_RENTALS";
export const RETURN_ASSET_RENTALS_PERMISSION = "RETURN_ASSET_RENTALS";
export const ACCEPT_ASSET_RENTALS_PERMISSION = "ACCEPT_ASSET_RENTALS";
export const DECLINE_ASSET_RENTALS_PERMISSION = "DECLINE_ASSET_RENTALS";
export const CREATE_GLOBAL_VIEWS_PERMISSION = "CREATE_GLOBAL_VIEWS";
export const UPDATE_GLOBAL_VIEWS_PERMISSION = "UPDATE_GLOBAL_VIEWS";
export const DELETE_GLOBAL_VIEWS_PERMISSION = "DELETE_GLOBAL_VIEWS";
export const CREATE_REPORTS_PERMISSION = "CREATE_REPORTS";
export const UPDATE_REPORTS_PERMISSION = "UPDATE_REPORTS";
export const DELETE_REPORTS_PERMISSION = "DELETE_REPORTS";
export const ALIAS_ATTRIBUTES_PERMISSION = "ALIAS_ATTRIBUTES";
export const SHOW_ALL_ASSETS_PERMISSION = "SHOW_ALL_ASSETS";
export const OPERATE_ALL_ASSETS_PERMISSION = "OPERATE_ALL_ASSETS";
export const MANAGE_ALERT_THRESHOLDS_PERMISSION = "MANAGE_ALERT_THRESHOLDS";
export const MANAGE_MULTIPLE_ALERT_THRESHOLDS_PERMISSION = "MANAGE_MULTIPLE_ALERT_THRESHOLDS";
export const CREATE_ALERT_MESSAGES_PERMISSION = "CREATE_ALERT_MESSAGES";
export const VIEW_DEVICE_STATUSES_PERMISSION = "VIEW_DEVICE_STATUSES";
export const VIEW_SUBSCRIPTIONS_PERMISSION = "VIEW_SUBSCRIPTIONS";
export const TRANSFER_ASSETS_PERMISSION = "TRANSFER_ASSETS";
export const ACCEPT_ASSET_TRANSFERS_PERMISSION = "ACCEPT_ASSET_TRANSFERS";
export const DECLINE_ASSET_TRANSFERS_PERMISSION = "DECLINE_ASSET_TRANSFERS";
export const LOCK_REMOTE_CONTROL_PERMISSION = "LOCK_REMOTE_CONTROL";
export const MIGRATE_ASSETS_PERMISSION = "MIGRATE_ASSETS";
export const UPDATE_SERVICE_HOURS_PERMISSION = "UPDATE_SERVICE_HOURS";
export const CREATE_TEMPLATES_PERMISSION = "CREATE_ASSET_TEMPLATES";
export const UPDATE_TEMPLATES_PERMISSION = "UPDATE_ASSET_TEMPLATES";
export const DELETE_TEMPLATES_PERMISSION = "DELETE_ASSET_TEMPLATES";
