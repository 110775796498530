// --------------------------------------------------------------
// Created On: 2023-06-01
// Author: Zachary Thomas
//
// Last Modified: 2024-09-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to products.
export const PRODUCT_TYPE_PUMP = "Pump";
export const PRODUCT_TYPE_LIFT_STATION = "Lift Station";
