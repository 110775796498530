// --------------------------------------------------------------
// Created On: 2023-06-23
// Author: Zachary Thomas
//
// Last Modified: 2024-09-29
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import { PRODUCT_TYPE_PUMP, PRODUCT_TYPE_LIFT_STATION } from "../../../constants/products";
import CentrifugalPumpVisual from "./CentrifugalPumpVisual/CentrifugalPumpVisual";
import LiftStationVisual from "./LiftStationVisual/LiftStationVisual";
import PropTypes from "prop-types";
import ProductDataTable from "./ProductDataTable/ProductDataTable";
import styles from "./ProductDataVisual.module.scss";

// A data visualization for a specific type of product.
export default function ProductDataVisual(props: Props): Component {
  const extraTallProductType = props.productType.includes(PRODUCT_TYPE_LIFT_STATION);
  const [attributeHoverList, setAttributeHoverList] = useState<boolean[]>([]);

  // See if the current product type should be displayed.
  function productTypeIsDisplayable(productType: string): boolean {
    return [PRODUCT_TYPE_PUMP, PRODUCT_TYPE_LIFT_STATION].includes(productType);
  }

  // Initialize the attribute hover list to show no attributes being hovered over.
  useEffect(() => {
    setAttributeHoverList(props.highlightAttributes.map(() => false));
  }, [JSON.stringify(props.highlightAttributes)]);

  // Set the current hover status of an attribute in the hover list.
  function updateHoverList(index: number, isHovering: boolean): void {
    setAttributeHoverList((prev) =>
      prev.map((hoverValue, i) => {
        if (i === index) {
          return isHovering;
        } else return hoverValue;
      })
    );
  }

  // Get the current attribute being hovered over, if any.
  function getHoverAttributeCode(): string | null {
    if (props.highlightAttributes.length === attributeHoverList.length) {
      const hoverIndex = attributeHoverList.findIndex((isHovering) => isHovering);
      if (hoverIndex > -1) {
        return props.highlightAttributes[hoverIndex].attributeCode;
      }
    }
    return null;
  }

  return (
    <div data-test="product-data-visual-container" className="my-3 py-3">
      <div className="row justify-content-center mx-0">
        <div className="col-12 col-xl-8">
          <div className={styles.container}>
            {props.assetName.length > 0 && <div className={styles.header}>{props.assetName}</div>}
            <div className="row g-0">
              <div
                className={`${styles.body} ${extraTallProductType ? styles.large : ""} col-12 ${
                  props.highlightAttributes.length > 0 ? "col-lg-6" : "col-lg-12"
                }`}
              >
                {props.productType === PRODUCT_TYPE_PUMP && (
                  <CentrifugalPumpVisual attributes={props.allAttributes} hoverCode={getHoverAttributeCode()} />
                )}
                {props.productType === PRODUCT_TYPE_LIFT_STATION && (
                  <LiftStationVisual attributes={props.allAttributes} hoverCode={getHoverAttributeCode()} />
                )}
                {props.productType !== null &&
                  props.productType !== "" &&
                  props.productType !== undefined &&
                  !productTypeIsDisplayable(props.productType) && (
                    <div className="row justify-content-center py-4">
                      <i className={`${styles.noImage} fa fa-fw fa-question-circle-o`} />
                      <div className={styles.noImageLabel}>No product image available.</div>
                    </div>
                  )}
              </div>

              {props.highlightAttributes.length > 0 && (
                <ProductDataTable
                  attributes={props.highlightAttributes}
                  onUpdateHoverList={(index, isHovering) => updateHoverList(index, isHovering)}
                  onSelectAttribute={(attributeId) => props.onSelectAttribute(attributeId)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductDataVisual.propTypes = {
  assetName: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  highlightAttributes: PropTypes.array.isRequired,
  allAttributes: PropTypes.array.isRequired,
  onSelectAttribute: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  productType: string;
  highlightAttributes: Attribute[];
  allAttributes: Attribute[];
  onSelectAttribute: (attributeId: number) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}
